<template>
  <a-modal :class="$style.container" v-model="isShow" title="密码重置" :width="500" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
        <a-form-model-item label="登录账号" prop="userName">
            {{ this.userName }}
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="newPassword">
            <a-input v-model="form.newPassword" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
  </a-modal>
</template>

<script>
import cryptoJs from 'crypto-js'

// 密码加密
const encrypt = (val) => {
  const key = cryptoJs.enc.Latin1.parse('jingyun2022leza1')
  const password = cryptoJs.AES.encrypt(val, key, {
    iv: key,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.ZeroPadding
  })
  return password.toString()
}
export default {
  inject: ['appRefs'],
  data () {
    const validPassword = (rule, value, callback) => {
      if (value && !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,18}$/.test(value)) {
        callback(new Error('密码必须包含字母、数字的6-18位字符'))
      }
      callback()
    }
    return {
      isShow: false,
      isDetermineLoading: false,
      userId: '',
      userName: '',
      form: {
        newPassword: ''
      },
      formRules: {
        newPassword: [
          { required: true, message: '请输入用户密码', trigger: 'change' },
          { validator: validPassword, trigger: 'change' }
        ]
      }
    }
  },
  watch: {
  },
  methods: {
    // 设置表单
    setForm (form = {}) {
      this.userId = form.id
      this.userName = form.userName
    },
    // 关闭
    close () {
      this.setForm()
      this.$refs.form.resetFields()
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          await this.$store.dispatch('http', {
            api: 'resetUserPassword',
            params: {
              newPassword: encrypt(this.form.newPassword)
            },
            query: {
              userId: this.userId
            },
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: '用户密码重置成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>

<template>
  <div :class="$style.container">
    <!-- 面包屑导航 -->
    <Breadcrumb :breadList="breadList">
       <a-button type="primary" @click="$refs.dialog.setForm();$refs.dialog.isShow = true;$refs.dialog.showStatus = true">新建</a-button>
    </Breadcrumb>
    <a-form ref="form" class="al-form" layout="inline" :model="form" :label-col="{ style: 'width: 80px' }" :wrapper-col="{ style: 'width: 170px' }">
      <a-form-item>
        <a-input v-model="form.userName" placeholder="登录账号" />
      </a-form-item>
      <a-form-item>
        <a-input v-model="form.name" placeholder="姓名" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isQueryLoading" @click="queryList">查询</a-button>
        <a-button @click="resetForm" style="margin-left:20px;">重置</a-button>
      </a-form-item>
    </a-form>

    <a-table :data-source="list" :rowKey="record => record.id" :columns="columns" :pagination="pagination" bordered>
      <span slot="action" slot-scope="user">
        <div style="align:center;">
          <a @click="$refs.dialog.setForm(user);$refs.dialog.isShow = true;$refs.dialog.showStatus = false">编辑</a>
          <a-divider type="vertical" />
          <a @click="$refs.dialog1.setForm(user);$refs.dialog1.isShow = true">重置密码</a>
          <a-divider type="vertical" />
          <a-popconfirm
              title="你确定要删除这行内容吗?"
              ok-text="是"
              cancel-text="否"
              @confirm="confirm(user)"
              @cancel="cancel"
            >
            <a href="#"><a-spin size="small" v-if="user.isLoading" />删除</a>
          </a-popconfirm>
        </div>
        <div style="align:center;">
          <a @click="$refs.roleDialog.user = $utils.clone(user);$refs.roleDialog.isShow = true">分配角色</a>
          <a-divider type="vertical" />
          <a-popconfirm
              title= "你确定要停用吗?"
              ok-text="是"
              cancel-text="否"
              @confirm="statusConfirm(user, 1)"
              @cancel="cancel"
              v-if="user.lockUser === 0"
            >
            <a href="#"><a-spin size="small" v-if="user.isLoading" />停用</a>
          </a-popconfirm>
          <a-popconfirm
              title= "你确定要启用吗?"
              ok-text="是"
              cancel-text="否"
              @confirm="statusConfirm(user, 0)"
              @cancel="cancel"
              v-if="user.lockUser === 1"
            >
            <a href="#"><a-spin size="small" v-if="user.isLoading" />启用</a>
          </a-popconfirm>
        </div>
      </span>
    </a-table>

    <dialog-save ref="dialog" @save="reloadList"></dialog-save>
    <reset-password ref="dialog1" @save="reloadList"></reset-password>
    <dialog-role-assign ref="roleDialog" @save="reloadList"></dialog-role-assign>
  </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb.vue'
import DialogSave from './dialog/addOrEdit.vue'
import DialogRoleAssign from './dialog/roleAssign.vue'
import ResetPassword from './dialog/password.vue'

export default {
  components: {
    Breadcrumb,
    DialogSave,
    DialogRoleAssign,
    ResetPassword
  },
  data () {
    return {
      breadList: [
        {
          name: '用户管理',
          url: ''
        }
      ],
      isQueryLoading: false,
      form: {
        userName: '',
        name: ''
      },
      columns: [
        { title: '登录账号', width: 130, dataIndex: 'userName', key: 'userName' },
        { title: '用户昵称', width: 100, dataIndex: 'nickName', key: 'nickName' },
        { title: '姓名', width: 100, dataIndex: 'name', key: 'name' },
        { title: '归属机构', width: 150, dataIndex: 'deptName', key: 'deptName' },
        { title: '邮箱', width: 150, dataIndex: 'email', key: 'email' },
        { title: '手机号', width: 140, dataIndex: 'phone', key: 'phone' },
        { title: '更新时间', width: 200, dataIndex: 'gmtModified', key: 'gmtModified' },
        { title: '操作', key: 'operation', fixed: 'right', width: 230, scopedSlots: { customRender: 'action' } }
      ],
      pagination: {
        total: 0,
        current: 1,
        pageSize: this.$enums.Pagination.pageSize,
        showLessItems: false,
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: this.$enums.Pagination.pageSizes,
        showTotal: (total, range) => `共 ${total} 条记录 第 ${range[0]}-${range[1]} 条数据`,
        onChange: pageNo => this.pageNoChange(pageNo),
        onShowSizeChange: (pageNo, pageSize) => this.pageSizeChange(pageNo, pageSize)
      },
      total: 0,
      list: []
    }
  },
  created () {
    this.reloadList()
  },
  methods: {
    // 重置表单
    resetForm (formName) {
      this.form.userName = ''
      this.form.name = ''
      this.queryList()
    },
    // 查询列表
    queryList () {
      this.pagination.current = 1
      this.isQueryLoading = true
      this.reloadList(() => {
        this.isQueryLoading = false
      })
    },
    // 重新加载列表（参数初始化）
    reloadList (callback = () => {}) {
      this.loadList(callback)
    },
    // 加载列表
    async loadList (callback = () => {}) {
      this.isTableLoading = true
      const params = Object.assign({
        pageNo: this.pagination.current,
        pageSize: this.pagination.pageSize
      }, this.form)
      const { total, records } = await this.$store.dispatch('http', {
        api: 'queryUsers',
        params,
        complete: () => {
          this.isTableLoading = false
          callback()
        }
      })
      this.pagination.total = total
      if (records) {
        records.forEach(item => {
          item.isLoading = false
        })
      }
      this.list = records
    },
    // 页码切换
    pageNoChange (pageNo) {
      this.pagination.current = pageNo
      this.loadList()
    },
    // 每页显示条数切换
    pageSizeChange (pageNo, pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.current = pageNo
      this.reloadList()
    },
    cancel () {},
    // 删除用户
    confirm (user) {
      this.del(user)
    },
    async del (user) {
      user.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'deleteUser',
        query: {
          id: user.id
        }
      })
      if (res) {
        user.isLoading = false
        this.$store.dispatch('showToast', { message: '删除成功~' })
        this.queryList()
      }
    },
    // 用户停用 启用
    statusConfirm (user, status) {
      this.updateStatus(user, status)
    },
    // 用户停用 启用
    async updateStatus (user, status) {
      // user.isLoading = true
      const res = await this.$store.dispatch('http', {
        api: 'updateUserStatus',
        query: {
          id: user.id,
          status: status
        }
      })
      if (res) {
        // user.isLoading = false
        this.$store.dispatch('showToast', { message: (status === 0 ? '启用' : '停用') + '成功~' })
        this.reloadList()
      }
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 10px 0;
  }
  .form__items {
    padding-top: 6px;
  }
}
</style>

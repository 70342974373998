<template>
  <a-modal :class="$style.container" v-model="isShow" :title="(this.form.id ? '编辑' : '新增') + '用户'" :width="500" @cancel="close" @ok="determine" :getContainer="_ => appRefs.app">
    <a-form-model ref="form" :class="$style.form__ct" :model="form" :rules="formRules" class="al-form" :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }">
      <a-form-model-item label="归属机构">
          <a-tree-select
            labelInValue
            :disabled="this.form.id"
            v-model="showItem"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :treeData="treeData"
            placeholder="请选择"
            @change="selectNode"
            allow-clear>
          </a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="用户昵称" prop="nickName">
            <a-input v-model="form.nickName" placeholder="请输入"/>
        </a-form-model-item>
        <a-form-model-item label="登录账号" prop="userName">
            <a-input v-model="form.userName"  placeholder="请输入" :disabled="!showStatus"/>
        </a-form-model-item>
        <a-form-model-item label="密码" prop="password" v-if="showStatus">
            <a-input v-model="form.password" placeholder="请输入" />
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
            <a-input v-model="form.phone" placeholder="请输入"/>
        </a-form-model-item>
         <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="form.email"/>
        </a-form-model-item>
         <a-form-model-item label="员工姓名">
            <a-input v-model="form.name"/>
        </a-form-model-item>
        <!-- 排序号 -->
        <!-- <a-form-model-item label="排序号" prop="sort">
            <a-input v-model="form.sort" placeholder="排序号"/>
        </a-form-model-item> -->
        <!-- 备注 -->
        <a-form-model-item label="备注">
            <a-input v-model="form.memo"  type = "textarea" placeholder="请输入你的介绍描述" :rows="4"/>
        </a-form-model-item>
      </a-form-model>
  </a-modal>
</template>

<script>
import cryptoJs from 'crypto-js'

// 密码加密
const encrypt = (val) => {
  const key = cryptoJs.enc.Latin1.parse('jingyun2022leza1')
  const password = cryptoJs.AES.encrypt(val, key, {
    iv: key,
    mode: cryptoJs.mode.CBC,
    padding: cryptoJs.pad.ZeroPadding
  })
  return password.toString()
}
export default {
  inject: ['appRefs'],
  data () {
    const validPassword = (rule, value, callback) => {
      if (value && !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\\W]{6,18}$/.test(value)) {
        callback(new Error('密码必须包含字母、数字的6-18位字符'))
      }
      callback()
    }
    const validPhone = (rule, value, callback) => {
      if (value && !(/^1[0-9]\d{9}$/.test(value))) {
        return callback(new Error('请输入正确的手机号码'))
      }
      callback()
    }
    const validEmail = (rule, value, callback) => {
      if (value && !(/^([a-zA-Z0-9]+[_|_|\-|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,6}$/.test(value))) {
        return callback(new Error('请输入正确的邮箱'))
      }
      callback()
    }
    return {
      showItem: {
        label: '',
        value: ''
      },
      isShow: false,
      showStatus: false,
      isDetermineLoading: false,
      form: {},
      formRules: {
        userName: [{ required: true, message: '请输入登录账号', trigger: 'change' }],
        nickName: [{ required: true, message: '请输入用户昵称', trigger: 'change' }],
        phone: [{ validator: validPhone, trigger: 'change' }],
        email: [{ validator: validEmail, trigger: 'change' }],
        password: [
          { required: true, message: '请输入用户密码', trigger: 'change' },
          { validator: validPassword, trigger: 'change' }
        ]
      },
      treeData: []
    }
  },
  watch: {
    isShow (val) {
      if (val) {
        this.getDeptTree()
        this.showItem = {
          value: this.form.deptId
        }
      }
    }
  },
  methods: {
    // 机构树
    async getDeptTree () {
      const res = await this.$store.dispatch('http', {
        api: 'queryOrganTree'
      })
      var a = this.convertToTreedata(res)
      // 脱掉一层再进行push
      this.treeData.push(...a)
    },
    // 数据构造
    convertToTreedata (data) {
      var returnData = []
      for (var i = 0; i < data.length; i++) {
        var tempObj = {
          title: data[i].name,
          value: data[i].id,
          key: data[i].id
        }
        // 判断是否存在子节点，如果存在则递归
        if ('children' in data[i]) {
          tempObj.children = this.convertToTreedata(data[i].children)
        }
        // push到数据数组中
        returnData.push(tempObj)
      }
      return returnData
    },
    selectNode (value) {
      if (value) {
        this.form.deptId = value.value
      }
    },
    // 设置表单
    setForm (form = {}) {
      this.form = {
        id: form.id,
        userName: form.userName,
        nickName: form.nickName,
        name: form.name,
        phone: form.phone,
        email: form.email,
        deptId: form.deptId,
        sort: form.sort,
        memo: form.memo,
        userId: form.userId
      }
    },
    // 关闭
    close () {
      this.setForm()
      this.$refs.form.resetFields()
      this.treeData = []
    },
    // 确定
    async determine (e) {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.isDetermineLoading = true
          const params = this.$utils.clone(this.form)
          let query = {}
          let api = 'addUser'
          if (params.id) {
            api = 'modifyUser'
            query = {
              id: this.form.id
            }
          } else {
            params.password = encrypt(this.form.password)
          }
          await this.$store.dispatch('http', {
            api,
            params,
            query,
            complete: () => {
              this.isDetermineLoading = false
            }
          })
          this.$store.dispatch('showToast', { message: (params.id ? '编辑' : '新增') + '用户成功~' })
          this.isShow = false
          this.close()
          this.$emit('save')
        }
      })
    }
  }
}
</script>

<style lang="scss" module>
.container {
  .form__ct {
    padding: 16px 0 10px;
  }
}
</style>
